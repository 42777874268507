'use client'
import { SessionData } from '@/app/api/auth/types'
import Loader from '@/components/atoms/Loader'
import { Alert } from '@/components/atoms/alerts/Alert'
import { useSubmitForm } from '@/components/atoms/forms/FormUtils'
import { RequestedFormsConfigType, useMergeFormsConfig } from '@/components/atoms/forms/InputConfigs'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { HTMLAttributes, useEffect, useState } from 'react'
import BaseFormStyled from '../elements/BaseFormStyled'
import FormBody from '../elements/FormBody'
import { classNames } from '../../../shared/classNames'

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const LoginForm = ({ className, ...props }: Props) => {
  const { data: session, status } = useSession()
  const router = useRouter()
  const searchParams = useSearchParams()
  const baseFormInputConfigurations: RequestedFormsConfigType = [
    {
      name: 'username',
      className: 'flex-1 text-sm',
      label: 'Email',
      placeholder: 'Email'
    },
    {
      name: 'password',
      className: 'flex-1 text-sm',
      label: 'Password',
      placeholder: 'Password'
    }
  ]
  const [errors, setErrors] = useState<Record<string, string>>({})

  const inputConfiguration = useMergeFormsConfig(baseFormInputConfigurations)

  const handleSignIn = async ({ username, password }: Record<string, string>) => {
    const response = await signIn('credentials', { email: username, password, redirect: false})

    if (response?.status === 401) {
      throw new Error("Email or Password is incorrect")
    }
    if (response?.error) {
      throw new Error(response.error)
    }
  }
  const handleSignInWithToken = async ({ token }: Record<string, string>) => {
    const response = await signIn('credentials', { email: "", password: "", redirect: false, token })

    if (response?.status === 401) {
      throw new Error("Email or Password is incorrect")
    }
    if (response?.error) {
      throw new Error(response.error)
    }
  }

  const { handleSubmit, isLoading } = useSubmitForm({
    service: handleSignIn,
    aditionalPayloadData: {},
    onErrorCallback: __ => {
      setErrors({ ...errors, generalError: 'Email or Password is incorrect' })
    },
    onSuccessCallback: () => null
  })

  const message = isLoading ? 'Loading...' : 'Log in'
  useEffect(() => {
    const error = searchParams.get('error')
    if (error) {
      setErrors({ ...errors, generalError: 'Email or Password is incorrect' })
    }
  }, [])

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      handleSignInWithToken({ token })
    }
  }, [])

  // Redirect if logged in already
  useEffect(() => {
    const user = session?.user as SessionData | undefined
    if (user) {
      router.push('/')
    }
  }, [session, router])

  return (
    <div className={classNames(className)} {...props}>
      {isLoading && <Loader position="absolute" className="opacity-50" />}
      <FormBody className="flex flex-col gap-3">
        {errors.generalError && <Alert theme="error">{errors.generalError}</Alert>}
        {/* {errors.generalError && <Alert theme="error">{errors.generalError}</Alert>} */}
        <BaseFormStyled
          className="flex-1"
          InputsConfig={inputConfiguration}
          onSubmit={handleSubmit}
          submitText={message}
          submitClassName="text-lg w-full"
          extraFromItem={
            <div className="flex justify-end w-full -mt-1 mb-2">
              <Link href="/auth/password-recovery" className="text-primary font-semibold hover:underline">
                Forgot password?
              </Link>
            </div>
          }
        />
      </FormBody>
    </div>
  )
}

export default LoginForm
