import styles from './Row.module.scss'
import { classNames } from '@/components/shared/classNames'

export interface RowProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
}

export const Row = ({ children, className, ...props }: RowProps) => {
  return (
    <div className={classNames(styles.row, className)} {...props}>
      {children}
    </div>
  )
}
