import NextLink from 'next/link'
import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from './Alert.module.scss'
import { Row } from '@/components/objects/Row'
import { Icon } from '../icon/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faInfoCircle,
  faQuestionCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'

export interface Props {
  className?: string
  theme?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error'
  children: string | React.ReactNode
  iconAnimationType?: 'none' | 'to-right' | 'pulse'
  showIcon?: boolean
}

const AVAILABLE_ICONS = {
  primary: <Icon name="circleCheck" className="w-6 h-6" />,
  secondary: <Icon name="circleCheck" className="w-6 h-6" />,
  tertiary: <Icon name="circleCheck" className="w-6 h-6" />,
  success: <Icon name="circleCheck" className="w-6 h-6" />,
  warning: <Icon name="triangleExclamation" className="w-6 h-6" />,
  error: <Icon name="circleExclamation" className="w-6 h-6" />
}

export const Alert = ({
  theme = 'primary',
  className = '',
  iconAnimationType = 'none',
  showIcon = true,
  ...props
}: Props) => {
  const enableChildHoverAnimationClass = 'group transition-all duration-300' // group class is used to enable child hover animation
  const componentProps = {
    className: classNames(
      enableChildHoverAnimationClass,
      styles.alert,
      styles[`alert--${theme}`],
      className,
      'fade-appear',
      'rounded-md gap-2'
    ),
    ...props
  }

  return (
    <Row {...componentProps}>
      {showIcon && AVAILABLE_ICONS[theme]}
      {componentProps.children}
    </Row>
  )
}
